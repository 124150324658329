<template>
  <div class="mcc_education con_area_bgcolor">
    <div class="mcc_project_con con_area">
      <div class="w11402">
        <h2>
          <span class="title">Education and Research</span>
        </h2>
        <div>
          <el-tabs
            :tab-position="cWidth > 1024 ? 'left' : 'top'"
            style="min-height: 300px"
          >
            <!-- 语言学习和娱乐课程 -->
            <el-tab-pane label="Language Studies and Recreation classes">
              <h1>Language Studies and Recreation classes</h1>
              <p>Holiday Courses:</p>
              <!-- <div class="mcc_img_col">
                <img src="../../assets/img/projects/education1.png" alt="" />
                <img src="../../assets/img/projects/education2.png" alt="" />
                <img src="../../assets/img/projects/education3.png" alt="" />
                <img src="../../assets/img/projects/education4.png" alt="" />
              </div> -->
              <div class="education_img">
                <div class="aboutus-text-div">
                  <!-- 第一个图片 -->
                  <div class="aboutus-text-div-card">
                    <a class="standard-card">
                      <!-- 图片div -->
                      <div class="standard-card-image">
                        <!-- 图片 -->
                        <div class="standard-card-image-div">
                          <img src="../../assets/img/projects/图片1.jpg" alt />
                        </div>

                        <!-- 半圆 -->

                        <div class="standard-card-image-transition"></div>
                      </div>

                      <!-- 文字 -->

                      <div class="standard-card-detail">
                        <div class="standard-card-detail-content">
                          <h2 class="class-title">CHINESE NCEA</h2>
                          <p class="class-title-content">
                            Intensive Holiday Course
                          </p>
                          <ul class="class-content">
                            <li>Get ready for your NCEA exam !</li>
                            <li>
                              Intensive immersion course over the holidays 2
                              hours of classes each day - $25
                            </li>
                          </ul>

                          <p class="zhucexinxi">INFORMATION & REGISTRATION</p>

                          <ul class="class-content-contact">
                            <li>
                              Manawatu Chinese Community Trust King Street,
                              Palmerston North
                            </li>

                            <li>06 3562629 | Wechat: wen631090</li>

                            <li>mccttrust@gmail.com</li>
                          </ul>
                        </div>
                      </div>
                    </a>
                  </div>

                  <!-- 第二个图片 -->
                  <div class="aboutus-text-div-card">
                    <a class="standard-card">
                      <!-- 图片div -->
                      <div class="standard-card-image">
                        <!-- 图片 -->
                        <div class="standard-card-image-div">
                          <img src="../../assets/img/projects/图片2.jpg" alt />
                        </div>

                        <!-- 半圆 -->

                        <div class="standard-card-image-transition"></div>
                      </div>

                      <!-- 文字 -->

                      <div class="standard-card-detail">
                        <div class="standard-card-detail-content">
                          <h2 class="class-title">ADULT CHINESE</h2>
                          <p class="class-title-content">
                            Intensive Holiday Programme
                          </p>
                          <ul class="class-content">
                            <li>Chinese radicals</li>
                            <li>Intensive spoken</li>
                            <li>Rapid Literacy in Chinese</li>
                          </ul>

                          <p class="zhucexinxi">INFORMATION & REGISTRATION</p>

                          <ul class="class-content-contact">
                            <li>
                              Manawatu Chinese Community Trust King Street,
                              Palmerston North
                            </li>

                            <li>06 3562629 | Wechat: wen631090</li>

                            <li>mccttrust@gmail.com</li>
                          </ul>
                        </div>
                      </div>
                    </a>
                  </div>

                  <!-- 第三个图片 -->

                  <div class="aboutus-text-div-card">
                    <a class="standard-card">
                      <!-- 图片div -->
                      <div class="standard-card-image">
                        <!-- 图片 -->
                        <div class="standard-card-image-div">
                          <img src="../../assets/img/projects/图片3.jpg" alt />
                        </div>

                        <!-- 半圆 -->

                        <div class="standard-card-image-transition"></div>
                      </div>

                      <!-- 文字 -->

                      <div class="standard-card-detail">
                        <div class="standard-card-detail-content">
                          <h2 class="class-title">NEW COMERS’ ENGLISH</h2>
                          <p class="class-title-content">
                            Intensive Holiday Programme
                          </p>
                          <ul class="class-content">
                            <li>Use the new language of where you stay!</li>
                            <li>
                              Intensive immersion course 2 hours of classes each
                              day - $25 per day
                            </li>
                          </ul>

                          <p class="zhucexinxi">INFORMATION & REGISTRATION</p>

                          <ul class="class-content-contact">
                            <li>
                              Manawatu Chinese Community Trust King Street,
                              Palmerston North
                            </li>

                            <li>06 3562629 | Wechat: wen631090</li>

                            <li>mccttrust@gmail.com</li>
                          </ul>
                        </div>
                      </div>
                    </a>
                  </div>

                  <!-- 第四个图片 -->

                  <div class="aboutus-text-div-card">
                    <a class="standard-card">
                      <!-- 图片div -->
                      <div class="standard-card-image">
                        <!-- 图片 -->
                        <div class="standard-card-image-div">
                          <img src="../../assets/img/projects/图片4.jpg" alt />
                        </div>

                        <!-- 半圆 -->

                        <div class="standard-card-image-transition"></div>
                      </div>

                      <!-- 文字 -->

                      <div class="standard-card-detail">
                        <div class="standard-card-detail-content">
                          <h2 class="class-title">ONE-ON-ONE</h2>
                          <p class="class-title-content">Intensive Study Aid</p>
                          <ul class="class-content">
                            <li>Get ready for your NCEA exam !</li>
                            <li>
                              Intensive study aid on science, biological,
                              statistic, accounting, chemistry with a teacher
                              specially for you -- $40 per day
                            </li>
                          </ul>

                          <p class="zhucexinxi">INFORMATION & REGISTRATION</p>

                          <ul class="class-content-contact">
                            <li>
                              Manawatu Chinese Community Trust King Street,
                              Palmerston North
                            </li>

                            <li>06 3562629 | Wechat: wen631090</li>

                            <li>mccttrust@gmail.com</li>
                          </ul>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <!-- <div class="w-accor w-accor4" id="Accor4">
                  <ul class="clearfix">
                    <li>
                      <div class="item clearfix">
                        <div class="img_ar">
                          <img src="../../assets/img/projects/education1.png" />
                        </div>
                        <div class="cont_ar">
                          <h3>chinese ncea</h3>
                        </div>
                      </div>
                      <a href="#"></a>
                    </li>
                    <li>
                      <div class="item clearfix">
                        <div class="img_ar">
                          <img src="../../assets/img/projects/education2.png" />
                        </div>
                        <div class="cont_ar">
                          <h3>adult chinese</h3>
                        </div>
                      </div>
                      <a href="#"></a>
                    </li>
                    <li>
                      <div class="item clearfix">
                        <div class="img_ar">
                          <img src="../../assets/img/projects/education3.png" />
                        </div>
                        <div class="cont_ar">
                          <h3>new comers'english</h3>
                        </div>
                      </div>
                      <a href="#"></a>
                    </li>
                    <li>
                      <div class="item clearfix">
                        <div class="img_ar">
                          <img src="../../assets/img/projects/education4.png" />
                        </div>
                        <div class="cont_ar">
                          <h3>one-on-one</h3>
                        </div>
                      </div>
                      <a href="#"></a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </el-tab-pane>
            <!-- 社区教育班 -->
            <el-tab-pane label="Community Educational classes">
              <h1>Community Educational classes</h1>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-trustpilot"></i>Recreation --Tai Chi /
                  Calligraphy / Painting / Dance / Musical Instruments / Fitness
                </li>
                <li>
                  <i class="iconfont icon-trustpilot"></i>Skills --
                  driving/cooking/gardening/sewing/employment
                  interview/accounting
                </li>
                <li>
                  <i class="iconfont icon-trustpilot"></i>Workshop-- Insurance /
                  Welfare / Security / Psychology / Health care
                </li>
                <li>
                  <i class="iconfont icon-trustpilot"></i>Business/ Pension /
                  Tax / Immigration
                </li>
              </ul>
            </el-tab-pane>
            <!-- 看我来看我走 -->
            <el-tab-pane label="See me come See me go">
              <h1>See me come See me go</h1>
              <p class="justify_align">
                Welcome visiting and exchanges between New Zealand and China.
              </p>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-trustpilot"></i>Messages being passed
                  through the Chinese embassy in New Zealand to the Trust with
                  on the annually Youngsters Holiday Camping in China. That
                  enables the Trust to organise students aged from 12 to 18 in
                  the community to go to China during their school holidays
                  either for culture explore or language study. The Trust also
                  introduces Chinese Language tutors, business people and
                  artists from local go to China for their relevant trainings
                  and visits.
                </li>
                <li>
                  <i class="iconfont icon-trustpilot"></i>After many
                  communicating and discussing with organisations in Auckland
                  and Wellington, Palmerston North will be one of their stops
                  for those professional cultural delegations, such as Chinese
                  folk dancers, singers, Kungfu, acrobatics, and magic
                  performers when they come to visit NZ.
                </li>
                <li>
                  <i class="iconfont icon-trustpilot"></i>The Trust itself also
                  has plan to invite Chinese arties, experts in psychology,
                  education, Chinese medicals, and successful business persons
                  etc, to bring their geniuses to NZ for a showcase and display.
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cWidth: sessionStorage.getItem("clientWidth"),
    };
  },
  mounted() {
    // this.accor($("#Accor4"), "38%", 500, false, false); //参数1:Dom节点，参数2：展开图宽度(值可正数像素值或百分比）、参数3：速度（ms）、参数4：初始是否展开（是true，否false）、参数5：是否点击展开（是true，否false）
  },
  methods: {
    // accor(acc, wd, speed, isOpen, isClickOpen) {
    //   var $accor = acc;
    //   var accorImg = $accor.find(".img_ar img");
    //   var $accorLi = $accor.find("li");
    //   var accorSet = function () {
    //     var wdC;
    //     var leng = $accorLi.length;
    //     var wdParent;
    //     var liOWid;
    //     var patt1 = new RegExp(/^\d+%$/);
    //     var isPatt = patt1.test(wd);
    //     var perc = 0;
    //     if (isPatt) {
    //       perc = wd.replace("%", "");
    //     }
    //     function widSetInit() {
    //       wdParent = $accor.width();
    //       if (isPatt) {
    //         wd = (parseInt(wdParent) * perc) / 100;
    //       }
    //       if (wdParent * 0.7 > wd) {
    //         wdC = wd;
    //       } else {
    //         wdC = wdParent * 0.7;
    //       }
    //       $accorLi.find(".img_ar").css({ width: wdC });
    //       liOWid = (wdParent - wdC - 2) / (leng - 1);
    //       $accorLi.css({ width: wdParent / leng });
    //       if (isOpen) {
    //         $accorLi
    //           .eq(0)
    //           .addClass("cur")
    //           .stop(true)
    //           .animate({ width: wdC }, speed)
    //           .siblings()
    //           .removeClass("cur")
    //           .stop(true)
    //           .animate({ width: liOWid }, speed);
    //       }
    //       setTimeout(function () {
    //         $accor.css({ height: $accor.height() });
    //         $accorLi.find(".img_ar").css({ height: $accor.height() });
    //       }, 200);
    //     }
    //     widSetInit();
    //     $(window).resize(function () {
    //       $accor.css({ height: "auto" });
    //       $accorLi.find(".img_ar").css({ height: "auto" });
    //       widSetInit();
    //     });
    //     if (isClickOpen) {
    //       $accor.addClass("accorClick");
    //       $accorLi.children("a").remove();
    //       $accorLi.on("click", function () {
    //         var _index = $(this).index();
    //         $(this)
    //           .addClass("cur")
    //           .stop(true)
    //           .animate({ width: wdC }, speed)
    //           .siblings()
    //           .removeClass("cur")
    //           .stop(true)
    //           .animate({ width: liOWid }, speed);
    //       });
    //     } else {
    //       $accor.addClass("accorNoClick");
    //       $accorLi.hover(
    //         function () {
    //           var _index = $(this).index();
    //           $(this)
    //             .addClass("cur")
    //             .stop(true)
    //             .animate({ width: wdC }, speed)
    //             .siblings()
    //             .removeClass("cur")
    //             .stop(true)
    //             .animate({ width: liOWid }, speed);
    //         },
    //         function () {
    //           if (!isOpen) {
    //             $accorLi
    //               .stop(true)
    //               .animate({ width: wdParent / leng }, speed)
    //               .removeClass("cur");
    //           }
    //         }
    //       );
    //     }
    //   };
    //   // 判断图片加载的函数
    //   var accrIsImgLoad = function (_obj) {
    //     if (_obj.height() === 0) {
    //       var t_img;
    //       t_img = setInterval(function () {
    //         clearInterval(t_img);
    //         accrIsImgLoad(_obj);
    //       }, 10);
    //     } else {
    //       accorSet(_obj);
    //     }
    //   };
    //   for (var i = 0; i < accorImg.length; i++) {
    //     var accrImg = $(accorImg[i]);
    //     var newImg = new Image();
    //     newImg.index = i;
    //     accrIsImgLoad($(accorImg[i]));
    //     newImg.src = accrImg.attr("src");
    //   }
    // },
  },
};
</script>

<style>
.mcc_img_col {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.mcc_img_col > img {
  width: auto;
  height: 42rem;
  margin: 1rem 0;
}
.education_img {
  width: 100%;
  margin: 0 auto;
}
.aboutus-text-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  /* display: inline-block; */
  /* float: left; */
}

.aboutus-text-div-card {
  width: 46%;
  border-radius: 20px;
  margin: 0 2%;
  margin-bottom: 40px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 6px 20px rgb(0 0 0 / 8%);
}

.aboutus-text-div-card > .standard-card {
  width: 100%;
}

.standard-card-image {
  width: 100%;
  height: 230px;
  position: relative;
}

.standard-card-image-div {
  width: 100%;
  height: 100%;
}

.standard-card-image-div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.standard-card-image-transition {
  background-color: #fff;
  position: absolute;
  padding-bottom: 9.11111%;
  bottom: -2px;
  left: -1%;
  box-sizing: border-box;
  width: 102%;
  border-radius: 60% 60% 0 0;
  overflow: hidden;
}

.standard-card-detail {
  background-color: #fff;
  width: 100%;
  height: 56.25%;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
}

.standard-card-detail-content {
  margin-bottom: 5px;
  /* text-align: center; */
}

.standard-card-detail-content > h2 {
  margin-bottom: 6px;
  text-align: center;
  color: #005bac;
  font-size: 2.3rem;
}

.class-title-content {
  /* padding: 10px 5px; */
  padding-bottom: 13px;
  box-sizing: border-box;
  /* margin-bottom: 16px; */
  text-align: center;
  color: #005bac;
  font-size: 1.8rem;

  border-bottom: 1px solid #ccc;
}
.standard-card-detail-content > ul {
  padding: 5px 15px;
  box-sizing: border-box;
  margin-top: 17px;
  margin-bottom: 5px;
  line-height: 150%;
  font-size: 1.6rem;
}
.class-content {
  border-bottom: 1px solid #ccc;
}

.class-content > li {
  margin-bottom: 10px;
}

.zhucexinxi {
  font-weight: 600;
  margin-top: 17px;
  font-size: 1.8rem;
}

.class-content-contact > li:nth-child(1),
.class-content-contact > li:nth-child(2) {
  margin-bottom: 10px;
}
/* 手风琴插件 */
/* .clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}
.w-accor {
  width: 100%;
  position: relative;
  margin-top: 1rem;
}
.w-accor ul,
.w-accor li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.w-accor ul {
  width: 101%;
}
.w-accor li {
  position: relative;
  overflow: hidden;
  float: left;
}
.accorClick li {
  cursor: pointer;
}
.w-accor li .item {
  position: relative;
}
.w-accor li > a {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.w-accor h3 {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-transform: uppercase;
}
.w-accor .acr_p {
  margin-top: 0.5em;
}

.w-accor4 li img {
  max-width: 100%;
  height: 100%;
}
.w-accor4 li .cont_ar {
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.w-accor4 li .cont_ar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #777;
  opacity: 0.35;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=35);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.w-accor4 li h3 {
  width: 1.2em;
  text-align: center;
  line-height: 1.5;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -0.6em;
  top: 50%;
  top: 10%\9;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  writing-mode: vertical-lr;
}
:root .w-accor4 li h3 {
  top: 50%;
}
.w-accor4 li .dec_ar {
  line-height: 1.7;
  max-height: 100%;
  position: absolute;
  z-index: 1;
  left: 40px;
  right: 40px;
  top: 50%;
  top: 10%\9;
  transform: translateY(-50%) scale(0);
  -webkit-transform: translateY(-50%) scale(0);
  padding: 30px;
  background: rgba(255, 255, 255, 0.75);
  color: #222;
  opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
:root .w-accor4 li .dec_ar {
  top: 50%;
}
.w-accor4 li .i_img {
  text-align: center;
  margin-bottom: 1em;
}
.w-accor4 li .i_img img {
  max-width: 80%;
  max-height: 80px;
}
.w-accor4 li.cur .cont_ar:before {
  opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}
.w-accor4 li.cur h3 {
  opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}
.w-accor4 li.cur .dec_ar {
  opacity: 1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  transform: translateY(-50%) scale(1);
  -webkit-transform: translateY(-50%) scale(1);
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
}

@media (max-width: 960px) {
  .w-accor h3 {
    font-size: 110%;
  }
  .w-accor .acr_p {
    font-size: 90%;
  }
  .w-accor4 li .img_ar {
    height: 350px !important;
  }
  .w-accor4 li .img_ar img {
    height: 100%;
    max-width: inherit;
  }
}
@media (max-width: 767px) {
  .w-accor4 li .dec_ar {
    left: 20px;
    right: 20px;
    padding: 15px;
  }
}
@media (max-width: 640px) {
  .w-accor {
    height: auto !important;
  }
}
@media (max-width: 480px) {
  .w-accor4 li .dec_ar {
    left: 1em;
    right: 1em;
    padding: 1em;
  }
  .w-accor4 li .img_ar {
    height: 20em !important;
  }
  .w-accor4 li .i_img img {
    max-height: 3em;
  }
} */
</style>